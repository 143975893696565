<template>
    <div class="content bg-gray-100 pt-32 lg:pt-40 pb-12 lg:pb-24">
        <div class="container mx-auto flex flex-col lg:flex-row">
            <div class="w-full lg:w-1/2 flex justify-center">
                <a :href="brochure" target="_blank" class="px-12 pb-6 lg:px-0">
                    <img src="@/assets/img/brochure.jpg" class="w-48 sm:w-64 shadow-lg">
                </a>
            </div>
            <div class="w-full px-6 lg:w-1/2 text-gray-700 flex flex-col justify-center">
                <h1 class="text-2xl lg:text-3xl font font-heading mb-2 font-bold">Something to remember us by<span class="font-mono text-red-600">...</span></h1>

                <div>
                    <p class="font-condensed leading-relaxed mb-4 text-lg">Not ready to commit to a new project? We get it. Sometimes you're just looking for some inspiration before starting that journey.</p>

                    <p class="font-body mb-4 font-light">We've compiled a brochure that you can download and keep for when you are ready to embark on your digital journey. It contains all the information you need about our prodcuts and services, as well detailing our process and how to get in touch.</p>

                    <p class="font-body text-lg mb-6">We're looking forward to <span class="font-semibold">creating your <span class="text-red-600">answer</span>.</span></p>

                    <a
                        :href="brochure"
                        target="_blank"
                        class="w-full bg-red-600 hover:bg-red-700 text-red-100 hover:text-red-200 py-3 font-condensed font-semibold flex justify-center items-center lg:w-40 shadow-lg"
                    >
                        <i class="gg-software-download mr-2"></i> Download
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import brochure from '@/assets/documents/brochure.pdf'

    export default {
        name: 'content-two-columns',
        components: {},
        data () {
            return {
                'brochure': brochure
            }
        }
    }
</script>

<style scoped>
    @import url('https://cdn.jsdelivr.net/npm/css.gg/icons/css/software-download.css');
</style>